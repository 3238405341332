import React from "react"
import PropTypes from "prop-types"
import BurgerMenu from "react-burger-menu"
import Link from "./link"

const BM = BurgerMenu['slide']

const Burger = ({ menuItems }) => (
    <BM id="burgerMenu" outerContainerId="mainHolder" width={300} isOpen={false} right>
      <ul>
      {
        menuItems.map((route, i) => (
            <li key={i} className="menu-items">
              <Link activeClassName="current-menu-item" to={route.url} fromWP={true}>{route.title}</Link>

              {
                route.wordpress_children && (
                    <ul className="sub-menu">
                      {route.wordpress_children.map((child, j) => (
                          <li key={j} className="menu-items">
                            <Link activeClassName="current-menu-item" to={child.url} fromWP={true}>{child.title}</Link>
                          </li>
                      ))}
                    </ul>
                )
              }
            </li>
        ))
      }
      </ul>
    </BM>
)

Burger.propTypes = {
  menuItems: PropTypes.array,
}

Burger.defaultProps = {
  menuItems: [],
}

export default Burger
