import React from "react"
import PropTypes from "prop-types"
import Link from "./link"

const Nav = ({ menuItems }) => (
    <nav id="topnav" className="nav nav__primary clearfix">
      <ul className="sf-menu">
      {
        menuItems.map((route, i) => (
            <li key={i} className="menu-items">
              <Link activeClassName="current-menu-item" to={route.url} fromWP={true}>{route.title}</Link>

              {
                route.wordpress_children && (
                    <ul className="sub-menu">
                      {route.wordpress_children.map((child, j) => (
                          <li key={j} className="menu-items">
                            <Link activeClassName="current-menu-item" to={child.url} fromWP={true}>{child.title}</Link>
                          </li>
                      ))}
                    </ul>
                )
              }
            </li>
        ))
      }
      </ul>
  </nav>
)

Nav.propTypes = {
  menuItems: PropTypes.array,
}

Nav.defaultProps = {
  menuItems: [],
}

export default Nav
