//export const env = 'dev';
export const env = 'production';

export const WP_URL = {
  dev: 'http://smartypartycms.local',
  production: 'https://admin.smartyparty.co.nz'
};

const Config = {
  wpURL: WP_URL[env]
};

export default Config;
