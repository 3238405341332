import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import Slider from 'react-slick'

class IndexPage extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage
    const pageClass = currentPage.title.replace(/\s+/g, '-').toLowerCase()
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 5000
    };

    return (
      <Layout pageClass="homepage">
        <SEO title={currentPage.title} />
        <Slider ref='slider' {...sliderSettings}>
          {
            currentPage.acf.slides.map((item, i) => (
                <div key={i} className="parallax-slider">
                  <Img fluid={item.image.localFile.childImageSharp.fluid} title={item.title} alt={item.caption} />
                  <div id="mainCaptionHolder">
                    <div className="container">
                      <div className="primaryCaption">
                        <div className="slider_caption">
                          <div className="service_box_px">
                            <h1>{item.title}</h1>
                            <h4>{item.caption}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            ))
          }
        </Slider>
        <div className="container homepage">
          <div className="row">
            <div className="span12">
              {/*<section className="title-section">*/}
                {/*<h1 className="title-header" dangerouslySetInnerHTML={{ __html: currentPage.title }} />*/}
              {/*</section>*/}
              <div className={`page-${pageClass}`} dangerouslySetInnerHTML={{ __html: currentPage.content }} />
            </div>
          </div>
        </div>
        <div className="spacer" />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
      acf {
        slides {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2592) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          caption
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
