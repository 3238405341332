import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import FontAwesome from "react-fontawesome"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import Nav from "./nav"
import Link from "./link"
import Burger from "./burger"
import { CustomerChat } from './customerChat';
import "./styles/index.css"

const Layout = ({ pageClass = '', children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        logoImage: file(relativePath: { eq: "logo.jpg" }) {
          childImageSharp {
            fixed(width: 162, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        footerImage: file(relativePath: { eq: "logo-inverse.png" }) {
          childImageSharp {
            fixed(width: 250, height: 158) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        allWordpressWpApiMenusMenusItems (filter: {slug: {eq: "main"}}) {
          edges {
            node {
              name
              items {
                title
                url
                type
                wordpress_children {
                  title
                  url
                  type
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <script type="text/javascript">
            {`
              var resource = document.createElement('link');
              resource.setAttribute("rel", "stylesheet");
              resource.setAttribute("href","//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
              resource.setAttribute("type","text/css");
              var head = document.getElementsByTagName('head')[0];
              head.appendChild(resource);
            `}
          </script>
          <script>
            {`
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
              m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  
              ga('create', 'UA-100440779-1', 'auto');
              ga('send', 'pageview');
            `}
          </script>
          <script src="https://js.stripe.com/v3/" />
        </Helmet>
        <div id="mainHolder" className="main-holder">
          <CustomerChat />
          <Burger menuItems={data.allWordpressWpApiMenusMenusItems.edges[0].node.items} />
          <header className="header">
            <div className="container">
              <div className="row">
                <div className="span12">
                  <div className="row">
                    <div className="span4">
                      <div className="logo pull-left">
                        <Link to="/" className="logo_h logo_h__img">
                          <div className="splogo">
                            <Img fixed={data.logoImage.childImageSharp.fixed} title="Smarty Party" alt="Smarty Party" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="span8">
                      <Nav menuItems={data.allWordpressWpApiMenusMenusItems.edges[0].node.items} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className={`content-holder clearfix ${pageClass}`}>{children}</div>
          <footer className="footer">
            <div className="container">
              <div className="row">
                <div className="span12">
                  <div>
                    <div className="inverse-logo">
                      <Img fixed={data.footerImage.childImageSharp.fixed} title="Smarty Party" alt="Smarty Party" />
                    </div>
                  </div>
                  <ul className="social">
                    <li>
                      <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/smartypartynz">
                        <FontAwesome name="facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" rel="noopener noreferrer" to="https://www.instagram.com/smartypartynz">
                        <FontAwesome name="instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" rel="noopener noreferrer" to="https://www.pinterest.com/smartypartynz">
                        <FontAwesome name="pinterest" />
                      </Link>
                    </li>
                  </ul>
                  <p className="copyright"><Link to="/terms">Terms and conditions</Link>&nbsp;|&nbsp;&copy; 2020 Smarty Party Ltd</p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
